@import 'utils/_variables';
@import "layout/_icons";
@import "components/_multiple-select";
.catalog-wrap {
    display: flex;
}

.catalog-aside {
    width: 264px;
}

.catalog-wrap-content {
    margin-left: auto;
    padding-left: 40px;
    width: calc(100% - 264px);
    max-width: 785px;
    position: relative;
    &.loading {
        >* {
            opacity: .1
        }
        .catalog-loader {
            opacity: 1
        }
    }
    .buy-add__text {
        font-size: 15px;
        font-weight: 600
    }
}

.catalog-check {
    margin-bottom: 29px;
    list-style: none;
    position: static;
    &__item {
        padding: 8px 0;
        margin-bottom: 7px;
    }
    &_list {
        .catalog-check__item {
            border-bottom: 2px solid rgba(0, 17, 24, 0.06);
        }
    }
    &_author {
        margin-bottom: 19px;
    }
    .catalog-check__item {
        padding: 8px 0 11px;
        margin-bottom: 0;
    }
}
.catalog-check_top {
    margin-top: -2px;
    margin-bottom: 23px;
    .catalog-check__item {
        padding: 9px 0 14px;
    }

}
.filter-item {
    margin-bottom: 24px
}

.filter-head {
    padding: 5px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px;
    border: 0;
    color: rgba(0, 17, 24, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    i {
        color: $color2;
        font-size: 12px;
        margin-top: -8px;
        transform: rotateX(0);
        display: inline-block;
        margin-left: 15px;
        flex-shrink: 0;
        transform: rotateX(180deg);
        transition: transform .5s ease, color .5s ease;
    }
    &__arr {
        color: $color-active;
    }
}

.web .filter-head__arr {
    transition: color .4s ease;
    &:hover {
        color: $dark;
    }
}

.filters-link {
    border-bottom: 2px solid rgba(0, 17, 24, 0.06);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $dark;
    &:last-child {
        border-bottom-width: 0;
    }
    a {
        padding: 8px 0;
        display: block;
        color: inherit;
        transition: none;
        span {
            margin-left: 2px;
            color: $color-active;
            transition: color .4s ease;
        }
    }
}

.web .filters-link a {
    transition: color .4s ease;
    &:hover {
        color: $color-active;
        span {
            color: $text-color;
        }
    }
}

.filter-select {
    margin: 0 0 25px;
    position: relative;
    z-index: 0;
    &.opened {
        .ms-choice {
            background: none;
            pointer-events: none;
            border-color: transparent;
            >span {
                display: none;
            }
        }
        .ms-choice>div.icon-close {
            pointer-events: auto
        }
    }
    .filter-head {
        padding: 0;
        margin-bottom: 0;
    }
    .hide-radio:first-child {
        height: 0;
        overflow: hidden;
    }
    &_author {
        margin-bottom: 0
    }
}

.filter-select.opened {
    z-index: 50
}

.filter-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    margin-bottom: 7px
}

.select-field {
    position: relative;
    .jq-selectbox.opened .jq-selectbox__trigger {
        display: none;
    }
    [type="search"] {
        -webkit-appearance: none;
        &::-webkit-search-cancel-button {
            opacity: 0
        }
    }
}

.jq-selectbox.opened~.close-popup-search {
    display: flex;
    opacity: 1;
    z-index: 102
}

.box-breadcrumbs {
    margin-bottom: 23px;
}

.breadcrumbs {
    font-size: 15px;
    line-height: 20px;
    list-style: none;
    li {
        display: inline-block;
        padding-left: 15px;
        margin-right: 11px;
        position: relative;
        &:after {
            content: '';
            display: block;
            background: #F0F1F1;
            width: 2px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0
        }
        &:first-child {
            padding-left: 0;
            &:after {
                display: none;
            }
        }
        span {
            color: rgba(0, 17, 24, 0.4)
        }
    }
    a,
    a span {
        color: $color-active;
        transition: color .4s ease;
        &:hover {
            color: $dark;
        }
    }
    a {
        font-weight: 500
    }
}

.counts-item {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: rgba(0, 17, 24, 0.4);
}

.page-head {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .h1 {
        margin: 0 20px 0 0;
    }
}

.field-autocomplete {
    position: relative;
    input[type="text"] {
        padding-right: 62px;
        &:focus,
        &.filled {
            &~.clear-popup-search {
                display: flex;
                opacity: 1;
            }
            &~.close-popup-search {
                display: none;
                opacity: 0
            }
        }
    }
    .close-popup-search {
        display: flex;
        opacity: 1;
        svg {
            color: $blue;
        }
        &:hover {
            svg {
                color: $color-active-2;
            }
        }
    }
}

.reset-filter-wrap {
    text-align: right;
}

.reset-filter {
    color: $color-active;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    background: none;
    padding: 5px 0;
    cursor: pointer;
    display: none;
    border: 0;
    font-family: $base-font-family;
    &.shown {
        display: inline-block;
    }
}

.web .reset-filter {
    transition: color .4s ease;
    &:hover {
        color: $color-active;
    }
}

.filter-arrow,
.filter-link {
    display: none
}

.main-catalog-page {
    padding: 29px 0 0;
     .catalog-wrap .products-card {
        width: calc(25% - 24px)
    }
}

.section-no-results {
    margin: 31px 0 24px;
}

.no-results {
    padding: 70px 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $dark;
    position: relative;
    z-index: 1;
    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
    }
    &__text {
        margin-top: 5px;
    }
    &__decor {
        position: absolute;
        z-index: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
        &_1 {
            //width: 18.87%;
            width: 228px;
            margin: -15px 0 0 -85px;
            background: rgba(142, 216, 248, 0.2);
        }
        &_2 {
            //width: 12.5%;
            width: 151px;
            margin: 58px 0 0 95px;
            background: rgba(142, 216, 248, 0.1);
        }
        &_3 {
            //width: 6.62%;
            width: 80px;
            margin: -73px 0 0 85px;
            background: rgba(179, 221, 65, 0.4);
        }
    }
}

.main-catalog-page_no-results .main-catalog+.main-catalog {
    margin-top: 40px;
}

.product-wrapper {
    padding-top: 29px;
}
.cardProduct {
    display: flex;
}

.leftCardProduct {
    padding-right: 24px;
    width: 38.5%
}

.rightCardProduct {
    width: 61.5%;
    display: flex;
}
.page-head-product {
    margin-bottom: 25px;

}
.h1-card {
        margin-bottom: 0;
font-weight: bold;
font-size: 40px;
line-height: 50px;
}
.product-author {
    margin-top: 4px;
    color: $color-active;
    font-weight: 500;
font-size: 15px;
line-height: 20px;
}
.slider-4:not(.slick-initialized) {
    opacity: 0;
    .slider-4 {
        opacity: 0;
    }
    .slide-main {
        position: absolute;
        opacity: 0
    }
    .slide-main:first-child {
        position: relative;
        opacity: 1
    }
}
.slider-4 {
    background: rgba(0, 17, 24, 0.06);
    &_nav {
        margin-top: 12px;
        padding: 0 38px
    }
    &_nav .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
        bottom: auto;
        margin: 0;
    width: 50px;
    height: 28px;
    border: 0;
    svg {
        color: $color-active;
    }   
    }
}
.slider-4_nav:not(.slick-initialized) {
    display: flex;
}
.slide-main {
    height: 500px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-height: 100%
    }
}

.slick-initialized .slide-main {
    display: flex;
}

.slide-nav {
    padding: 0 6px;
    cursor: pointer;
    position: relative;
    &__img {
        position: relative;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        vertical-align: top;
        position: absolute;
        left: 6px;
        top: 0;
        right: 6px;
        height: 100%;
        &:after {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
    &:before {
        content: '';
        display: block;
        padding-top: 100%
    }



}

.characterictics-wrap {
    width: calc(100% - 312px);
    padding-right: 24px
}

.characterictics {
    padding: 0 12px 12px;
    border: 1px solid rgba(0, 17, 24, 0.06);
    &__list {
        max-height: 292px;
        overflow: hidden;
        transition: max-height .3s ease .1s;
    }
    &__item {
        padding: 11px 12px 10px;
        border-bottom: 1px solid rgba(0, 17, 24, 0.06);
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-child {
            border-bottom-width: 0;
        }
    }
    &__label {
        font-size: 12px;
        line-height: 18px;
        margin-right: 15px
    }
    &__value {
        font-size: 14px;
        line-height: 20px;
        color: $dark;
        text-align: right;
    }

}

.product-details {
    width: 312px
}

.product-prices {
    padding: 24px 24px 23px;
    box-shadow: 4px 4px 14px 2px rgba(0, 46, 89, 0.15);
    border-radius: 6px;
    .basket-item__quantity {
        display: flex;
        align-items: center;
    }
    .counter-box {
        border-radius: 6px;
        background: #F0F1F1;
        height: 56px;
        display: flex;
        align-items: center;
        width: calc(100% - 68px)
    }
    .counter-box .counter-input {
        background: none;
        width: calc(100% - 124px)
    }
    .counter-box .counter-link {
        background: none;
        height: 40px;
        width: 50px;
        position: relative;
        margin: 8px 0;
        &.disabled {
            &:before, &:after {opacity: .2}
        }
    }
    .counter-link__prev {
        border-right: 2px solid rgba(0, 17, 24, 0.06);
        &:before {
            right: 0;
        }
    }
    .counter-link__next {
        border-left: 2px solid rgba(0, 17, 24, 0.06);

    }
    &__label {
        font-size: 12px;
line-height: 18px;
margin-bottom: 6px;
    }
}

.products-favorite {
    margin-left: 12px;
    width: 56px;
    height: 56px;
    background: #F0F1F1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.in-favorite {
        color: $red;
    }
}

.buttonBuy {
    margin-top: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    padding: 8px 20px;
    svg {
        margin-right: 8px;
    }
}

.promo {
    padding: 19px;
    color: $dark;
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: rgba(0, 17, 24, 0.06);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .4s ease;
    img {
        margin-left: 5px;
        max-height: 20px
    }
    &:hover {
        color: $dark;
        background: rgba(0, 17, 24, 0.16);
    }
}

.product-price {
    display: inline-block;
    &__row {
        padding: 0 0 12px;
        margin-bottom: 13px;
        border-bottom: 1px solid rgba(0, 17, 24, 0.06);
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    &__discount {

    font-weight: 500;
font-size: 12px;
line-height: 18px;
color: $red;
margin-left: 15px;
display: inline-block;
padding-bottom: 5px;
    }
}

.product-price__item {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: $dark;
}

.price_old {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    opacity: .4;
    span {
        position: relative;
        &:before {
            content: '';
            display: block;
            width: 100%;
            transform: rotate(-20deg);
            background: $dark;
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
        }
    }
}
.btn_no-found {
    margin-top: 39px;
    min-width: 200px
}
.no-found {
    padding-top: 7px;
    .no-results__text {
        font-size: 15px
    }
}
.no-found__title {
    margin-bottom: 24px;
    font-weight: bold;
font-size: 114px;
line-height: 120px;
color: $color-active;
font-family: $custom-font-family;
}
.main-catalog-page_no-results {
    overflow: hidden;
}
.article {
    max-width: 936px;
    margin: 29px auto 0;
    line-height: 1.55;
    p {
        padding: 6px 0;
    }
    b, strong {
        font-weight: 500
    }
    figure {
        margin: 54px 0 40px;
        text-align: center;
    }
    figcaption {
        font-size: 12px;
line-height: 18px;
text-align: left;
margin-top: 3px;
color: rgba(0, 17, 24, 0.4);
    }
    h2 {
        padding-top: 20px;
        margin-bottom: 7px
    }
}
.article-author {
padding-right:0.2%;
 font-size: 14px;
line-height: 20px;
padding-bottom: 14px;
p {
    padding: 6px 0;
}
&__img {
    width: 22.5%;
    float: left;
    margin: 0 24px 16px 0;
    position: relative;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
        img{
            position: absolute;
            display: block;
            object-fit: cover;
            object-position: 50% 50%;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 50%
        }
}
    .article-author__img + p {
        padding-top: 0
    }
}
.collections-head {
    padding-top: 41px;
    padding-bottom: 42px;
    position: relative;
    background: $blue;
    &__title {
        width: 48%;
        padding-right: 30px;
        .btn {
            min-width: 168px;
        }
    }
    &__pictures {
        max-width: 533px;
        width: calc(52% + 12px);
        margin: 0 -6px;
        display: flex;
        align-items: flex-start;
    }
        &__img {
            margin: 0 6px;
            position: relative;
            width: calc(33.33% - 12px);
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 6px 12px rgba(0, 46, 89, 0.12);
            &:before {
                content: '';
                display: block;
                padding-top: 131%;
            }
        img {
            position: absolute;
            display: block;
            object-fit: cover;
            object-position: 50% 50%;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }
        &:nth-child(2) {
            margin-top: 30px;
        }
        &:nth-child(3) {
            margin-top: 60px;
        }
        }
    .decor {
    background: rgba(255, 255, 255, 0.2);
&-1 {
    width: 23.52%;
    max-width: 334px;
    right: 6.5%;
        transform: translate(-50%, 48%);
    }
&-2 {

    width: 23.52%;
    max-width: 334px;

    transform: translate(-10%, -43%);
    }
&-3 {

    width: 21.8%;
    bottom: auto;
    max-width: 314px;
    top: 0;
    left: 0;
        transform: translate(-11%, -43%);
    }
&-4 {
    width: 14.8%;
    max-width: 208px;
    left: 35%;
    top: 63%;
    transform: translate(-50%, -50%);

    }
    }
}
.wrapper-collections {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    &:after {
        display: none;
    }
}
.main-catalog-page_favorite .catalog-wrap .products-card {
    width: calc(16.66% - 24px)
}
.main-catalog-page_no-results {
    padding-top: 29px
}
@media screen and (max-width: 1366px) {
    .main-catalog-page_no-results .main-catalog+.main-catalog {
        margin-top: 19px;
    }
}

@media screen and (max-width: 1180px) {
.main-catalog-page .catalog-wrap {
    .products-card {
        width: calc(33% - 24px)
    }
}
.main-catalog-page_favorite .catalog-wrap .products-card {
    width: calc(20% - 24px)
}
}
@media screen and (max-width: 1146px) {
    .catalog-aside {
        width: 180px;
    }
    .catalog-wrap-content {
        padding-left: 40px;
        width: calc(100% - 180px);
    }
    .characterictics-wrap {
    width: calc(100% - 300px);
}
    .product-details {
    width: 300px;
}
.characterictics__value {
    font-size: 13px;
    line-height: 19px;
    }
    .leftCardProduct {
        width: 36%
    }
    .rightCardProduct {
    width: 64%;
}
}

@media screen and (max-width: 1023px) {
    .catalog-wrap {
        display: block;
    }
    .catalog-wrap-content {
        padding-left: 0;
        width: 100%;
        max-width: 100%
    }
    .filter-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            color: $blue
        }
    }
    .catalog-aside {
        padding: 11px 24px 80px;
        position: fixed;
        top: 0;
        left: -110%;
        bottom: 0;
        overflow: auto;
        background: #fff;
        transition: left .8s ease;
        will-change: left;
        width: 360px;
        max-width: 100vw;
        z-index: 100;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &.opened-filters {
            left: 0;
        }
    }
    .cardProduct {
        display: block;
    }
    .leftCardProduct {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        max-width: 550px
    }
    .rightCardProduct {
        margin-top: 24px;
    width: 100%;
}
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .main-catalog-page {
        .catalog {
            margin: 0 -9px
        }
         .catalog-wrap .products-card {
            margin-left: 9px;
            margin-right: 9px;
            width: calc(25% - 18);
        }
    }
.main-catalog-page_favorite .catalog-wrap .products-card {
    width: calc(25% - 24px)
}
}

@media screen and (max-width: 767px) {
    .box-breadcrumbs {
        margin-bottom: 7px;
    }
    .main-catalog.main-catalog-page {
        padding: 4px 0 0
    }
    .page-head {
        margin-bottom: 24px;
        justify-content: flex-start;
        .h1 {
            margin-right: 0
        }
    }
    .breadcrumbs {
        font-size: 12px;
        line-height: 18px;
        li {
            margin-bottom: 6px
        }
    }
    .main-catalog-page {
    .buy-add__button svg {
        display: none
    }
        .catalog {
            margin: 0 -6px
        }
         .catalog-wrap .products-card {
            margin-bottom: 24px;
            padding: 8px;
            margin-left: 6px;
            margin-right: 6px;
            width: calc(25% - 12px);
        }
    }
    .counts-item {
        margin-left: 5px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 700
    }
    .main-catalog-page_no-results {
        padding-top: 12px;
    }
    .main-catalog-page_no-results .main-catalog+.main-catalog {
        margin-top: 9px;
    }
    .product-wrapper, .main-catalog-page, .main-catalog-page_no-results {
    padding-top: 2px;
}
.page-head-product {
    margin-top: 18px;
}
.product-author {
    margin-top: 8px;
    }
    .h1-card {
        font-size: 24px;

line-height: 34px;
    }
    .slide-main  {
        padding: 18px;
        height: 383px;
    }
    .slider-4_nav {
        display: none;
    }
    .slider-4 {
        .slick-dots {
                margin-top: 2px;
            padding-top: 12px;
            background: #fff
        }
    }.rightCardProduct {
    margin-top: 14px;
        flex-direction: column-reverse;
    }
    .product-price__row {
    padding: 0 0 9px;

}
.product-details {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
}
    .product-prices {
    padding: 0;
    border: 0;
    box-shadow: none;
}

.promo {
    height: 56px;
    line-height: 54px
}
.characterictics {
    padding: 0;
    border: none;
    margin-top: 22px
}
    .characterictics-wrap {
        width: 100%;
        padding: 0
    }
    .characterictics__item {
        padding-left: 0;
        padding-right: 0
    }
    .characterictics__value {
    font-size: 14px;
    line-height: 20px;
}
.no-found {
    padding-bottom: 0
}
.article-author__img {
    width: 30.5%;
    max-width: 168px;
    margin: 0 15px 14px 0;
    }
    .wrapper-collections {
        display: block;
    }
    .collections-head__title {
        max-width: 480px;
    width: auto;
    padding-right: 0;
    margin-right: -5px
}
.collections-head__pictures {
    width: auto;
    margin-right: -42px;
    margin-top: 39px;
}
.collections-head__title .btn {
    width: 100%
}
.collections-head__img:before {
 
    padding-top: 150%;
}
.collections-head__img:nth-child(2) {
    margin-top: 23px;
}
.collections-head__img:nth-child(3) {
    margin-top: 46px;
}
.collections-head {
    padding-bottom: 29px;
    }
    .collections-list .article {
        font-size: 18px;
        line-height: 28px;
    }
}
@media screen and (max-width: 690px) {
.main-catalog-page .catalog-wrap .products-card {
    width: calc(33.33% - 12px);
}
}
@media screen and (max-width: 520px) {
.main-catalog-page .catalog-wrap .products-card {
    width: calc(50% - 12px);
}
}

@media screen and (min-width: 768px) {
.no-found   {
   & ~ .no-results__decor_1 {
    width: 245px;
    margin: 13px 0 0 -98px;
}
&~ .no-results__decor_2  {
    margin-top: 69px;
    }
    &~ .no-results__decor_3 {
        width: 127px;
        margin: -60px 0 0 102px;
    }
}
}