
.ms-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: auto !important;
    top: auto !important;
}

.ms-parent {
    max-width: 100%;
    width: 100%;
    position: relative;
    font-size: 15px;
    line-height: 20px;
    color: $dark;
    vertical-align: middle;
}

.ms-choice {
    height: 56px;
    padding: 14px 52px 14px 12px;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    background: #f0f1f1;
    border: 2px solid #f0f1f1;
    width: 100%;
    color: $dark;
    text-align: left;
    border-radius: 6px;
    z-index: 1001;
    transition: border .3s ease, background .3s ease;
}

.ms-choice.disabled {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.ms-choice>span {
    padding-right: 6px;
    width: 100%;
    overflow: hidden;
    font-size: 14px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.placeholder {
        color: rgba(0, 17, 24, 0.4);
    }
}




.ms-choice>div.icon-caret {
  content: '';
    position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 100%;
    font-size: 10px;
    display: block;
background: url(../img/icons-svg/down.svg) 50% 50% no-repeat;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    &:before {
        content: '';
        display: block;
        background: rgba(0, 17, 24, 0.06);
        position: absolute;
        left: -5px;
        top: 8px;
        bottom: 8px;
        width: 2px;
    }
    &.open {
      display: none
    }
}

.ms-choice>div.icon-caret.open {
    transform: rotateX(180deg);
    margin-top: -6px;
}

.ms-drop {
    width: 100%;
    display: none;
    margin: -56px 0 0;
    padding: 0;
    position: absolute;
    z-index: 1000;
    background: #FFFFFF;
    font-size: 15px;
}

.ms-drop.bottom {
    top: 100%;
}

.ms-drop.top {
    bottom: 100%;
}

.ms-search {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000;
    box-sizing: border-box;
}

.ms-search input {
    padding: 0 62px 0 12px;
    width: 100%;
    &:focus {
        border-color: $color-active
    }
}

.ms-drop ul {
    background: #FFFFFF;
    border-radius: 0 0 6px 6px;
    overflow: auto;
    margin: 0;
    max-height: 260px;
    padding: 0;
    box-shadow: 4px 4px 14px 2px rgba(0, 46, 89, 0.15);
}

.ms-drop ul>li {
    list-style: none;
    display: list-item;
    background-image: none;
    position: static;

    span {
      display: flex;
    }
    span:before {
        content: '';
        display: block;
        width: 22px;
        height: 22px;
        content: '';
        border: 2px solid $dark;
        margin-right: 13px;
        transition: border-color .4s ease
    }
    &.selected span:before {
        transition: background-color 0.3s ease-in, border 0.3s ease-in;
        background-color: #8ED8F8;
        background-image: url(../img/icons-svg/check.svg);
        background-repeat: no-repeat;
        -webkit-background-size: 13px 11px;
        background-size: 13px 11px;
        background-position: 50% 50%;
    }
}

.ms-drop ul>li .disabled {
    font-weight: normal !important;
    opacity: .35;
    filter: Alpha(Opacity=35);
    cursor: default;
}

.ms-drop ul>li.multiple {
    display: block;
}

.ms-drop ul>li.group {
    clear: both;
}

.ms-drop ul>li.multiple label {
    padding-left: 25px;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s linear;
    &:hover {
        color: #E72D2B;
    }
}

.ms-drop ul>li label {
    position: relative;
    padding: 10px 3px 9px;
    margin-bottom: 0;
    font-weight: normal;
    display: block;
    cursor: pointer;
    font-size: 12px;
    &:hover {
        font-weight: 500;
        span:before {
            border-color: $color-active;
        }
    }
}

.ms-drop ul>li label.optgroup {
    font-weight: bold;
}

.ms-drop ul>li.hide-radio {
    padding: 0;
}
.ms-drop ul>li.hide-radio:focus,
.ms-drop ul>li.hide-radio:hover{
  font-weight: 500;
            background: rgba(240, 241, 241, 0.8);
    color: $dark;
}
.ms-drop ul>li.hide-radio {
    border-bottom: 0
}
.ms-drop ul>li.hide-radio label {
    padding: 12px;
    font-size: 14px;
    span {
        &:before {
            display: none;
        }
    }
  }
    .ms-drop ul>li {
        border-bottom: 1px solid rgba(0, 17, 24, 0.06);
    }
    .ms-drop ul>li.hide-radio input {
        display: none;
    }
    .ms-drop ul>li.option-level-1 label {
        padding-left: 28px;
    }
    .ms-drop input[type="radio"],
    .ms-drop input[type="checkbox"] {
        position: absolute;
        margin-top: .3rem;
        opacity: 0;
    }
    .ms-drop .ms-no-results {
        padding: 12px;
        display: none;
    }
    .ms-drop ul>li.multiple label span:after,
    .ms-select-all label span:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        left: 0;
        top: 2px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        position: absolute;
        z-index: 0;
    }

.icon-close .ms-choice > div.icon-close {
  display: none
}
.filled .ms-choice > div.icon-close, .ms-choice > div.icon-close.open {
    display: flex;
    border-left-color: #e2e4e4;
    opacity: 1
  }
.filled {
  .ms-choice > div.icon-caret {
    display: none
  }
  
}
.select-field_multiple {
    .ms-choice > div.icon-caret {
        background-image: url(../img/icons-svg/search.svg)
    }
}

@media screen and (min-width: 1024px) {
.ms-drop ul > li label {
    padding-left: 12px
}
}